import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {DialogModel} from '@core/models/dialog.model';


@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  options: DialogModel;

  constructor(
    private bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
    if (!this.options.confirmText) {
      this.options.confirmText = 'OK';
    }
  }

  onClose(): void {
    this.bsModalRef.hide();
  }

  onConfirm(): void {
    this.onClose();

    if (this.options.onConfirm) {
      this.options.onConfirm();
    }
  }
}
