import {Component, OnDestroy} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';

import {UntState} from '@core/states/unt/unt.state';
import {ListExams, ViewExam} from '@core/states/unt/actions';
import {HTTP_PARAMS_SKIP_LOADER_SHOWING} from '@core/constants/constants';
import {ExamModel} from '@core/models/exam.model';


@Component({
  templateUrl: './unt-list.component.html',
  styleUrls: ['./unt-list.component.scss']
})
export class UntListComponent implements OnDestroy {

  @Select(UntState.idn) idn$: Observable<string>;
  @Select(UntState.exams) exams$: Observable<any[]>;

  refreshStatus: 'in_progress' | 'done';

  constructor(
    private store: Store
  ) {
  }

  ngOnDestroy(): void {
  }

  onViewExam(exam: ExamModel): void {
    this.store.dispatch(new ViewExam(exam));
  }

  onRefresh(): void {
    this.refreshStatus = 'in_progress';

    setTimeout(() => {
      this.store.dispatch(new ListExams({[HTTP_PARAMS_SKIP_LOADER_SHOWING]: true}))
        .toPromise()
        .finally(() => {
          this.refreshStatus = 'done';
          setTimeout(() => this.refreshStatus = null, 3000);
        });
    }, 1000);
  }
}
