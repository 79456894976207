import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiService} from '@core/services/api.service';
import {ListModel} from '@core/models/list.model';


@Injectable({
  providedIn: 'root'
})
export class GroupsService extends ApiService {

  constructor(
    protected http: HttpClient
  ) {
    super('groups');
  }

  fetch(params: any): Observable<ListModel<any>> {
    const url = this.getMainUrl();
    params = {...params, page_size: 100};

    return this.http.get<ListModel<any>>(url, {params});
  }
}
