import {Injectable} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';

import {DialogModel} from '@core/models/dialog.model';
import {DialogComponent} from '@core/components/dialog/dialog.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private bsModalService: BsModalService
  ) {
  }

  show(options: DialogModel): void {
    this.bsModalService.show(DialogComponent, {
      initialState: {options},
      class: 'modal-dialog-centered modal-dialog-centered-custom'
    });
  }
}
