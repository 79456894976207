import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {MOBILE_ONE_SIGNAL_PARAMS_OBSERVABLE} from '@core/constants/constants';
import {rnWebviewPostMessage} from '@core/utils/rn-webview';


@Injectable({
  providedIn: 'root'
})
export class OneSignalParamsService {

  constructor() {
    window[MOBILE_ONE_SIGNAL_PARAMS_OBSERVABLE] = new Subject<any>();
  }

  get oneSignalParams$(): Observable<any> {
    return window[MOBILE_ONE_SIGNAL_PARAMS_OBSERVABLE].asObservable();
  }

  handleOneSignalParams(params: any): void {
    if (!params) {
      return;
    }

    try {
      // TODO: remove log and process params
      rnWebviewPostMessage({
        event: 'log',
        data: `OneSignal params: ${JSON.stringify(params)}`
      });
    } catch (e) {
      rnWebviewPostMessage({
        event: 'log',
        data: `Error while processing OneSignal params: ${JSON.stringify(e)}`
      });
    }
  }
}
