import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';


@Pipe({
  name: 'vimeoUrl'
})
export class VimeoUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  transform(id: number): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`//player.vimeo.com/video/${id}?title=0&amp;byline=0&amp;portrait=0`);
  }
}
