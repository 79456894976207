import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {CoreModule} from '@core/core.module';
import {UntListComponent} from '@features/unt-list/unt-list.component';
import {IdnEditComponent} from '@features/idn-edit/idn-edit.component';
import {UntResultComponent} from '@features/unt-result/unt-result.component';
import {GroupListComponent} from '@features/group-list/group-list.component';
import {EnrollInfoComponent} from '@features/enroll-info/enroll-info.component';


@NgModule({
  declarations: [
    AppComponent,
    UntListComponent,
    IdnEditComponent,
    UntResultComponent,
    GroupListComponent,
    EnrollInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
