import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';

import {LoaderComponent} from '@core/components/loader/loader.component';
import {DialogComponent} from '@core/components/dialog/dialog.component';
import {AppState} from '@core/states/app/app.state';
import {AuthState} from '@core/states/auth/auth.state';
import {LoaderState} from '@core/states/loader/loader.state';
import {UntState} from '@core/states/unt/unt.state';
import {LoaderInterceptor} from '@core/interceptors/loader.intercepter';
import {RequestInterceptor} from '@core/interceptors/request.interceptor';
import {VimeoUrlPipe} from '@core/pipes/vimeo-url.pipe';
import {TranslatePipe} from '@core/pipes/translate.pipe';
import {SharedImgComponent} from '@core/components/img/shared-img.component';
import {NamePipe} from '@core/pipes/name.pipe';
import {MomentPipe} from '@core/pipes/moment.pipe';


@NgModule({
  declarations: [
    LoaderComponent,
    DialogComponent,
    SharedImgComponent,
    VimeoUrlPipe,
    TranslatePipe,
    NamePipe,
    MomentPipe,
  ],
  exports: [
    LoaderComponent,
    DialogComponent,
    SharedImgComponent,
    VimeoUrlPipe,
    TranslatePipe,
    NamePipe,
    MomentPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([AuthState, AppState, UntState, LoaderState]),
    NgxsRouterPluginModule.forRoot(),
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true
        },
      ]
    };
  }
}
