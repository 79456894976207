import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE} from '@core/constants/constants';
import {TokenModel} from '@core/models/token.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {
    window[MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE] = new Subject();
  }

  get onMobileAppFinishRefreshingToken$(): Observable<TokenModel> {
    return window[MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE].asObservable();
  }
}
