import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'core-img',
  templateUrl: './shared-img.component.html',
  styleUrls: ['./shared-img.component.scss'],
})
export class SharedImgComponent implements OnInit {

  @Input() name: string;
  @Input() format = 'svg';

  @Input() width: number;
  @Input() height: number;
  @Input() size: number;

  @Input() hover: string;
  temp: string;

  ngOnInit(): void {
    if (this.size) {
      this.width = this.size;
      this.height = this.size;
    }
  }

  onMouseEnter(): void {
    if (this.hover) {
      this.temp = this.name;
      this.name = this.hover;
    }
  }

  onMouseLeave(): void {
    if (this.hover) {
      this.name = this.temp;
    }
  }
}
