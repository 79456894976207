import {TokenModel} from '@core/models/token.model';


export class InitAuthToken {
  static readonly type = '[Auth] InitAuthToken';
}

export class UpdateToken {
  static readonly type = '[Auth] UpdateToken';

  constructor(public token: TokenModel) {
  }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
