import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {ApiService} from '@core/services/api.service';
import {ExamModel} from '@core/models/exam.model';
import {HTTP_PARAMS_SKIP_ERROR_HANDLING, HTTP_PARAMS_SKIP_LOADER_SHOWING} from '@core/constants/constants';
import {rnWebviewLog} from '@core/utils/rn-webview';


@Injectable({
  providedIn: 'root'
})
export class UntService extends ApiService {

  constructor(
    protected http: HttpClient
  ) {
    super('unt_v3');
  }

  listExams(params?: any): Observable<ExamModel[]> {
    return this.http.get<ExamModel[]>(this.getMainUrl('exams'), {params})
      .pipe(
        map(exams => exams.map(exam => {
            return ({
              ...exam,
              status: exam.result ? 'has_result' : 'not_registered'
            });
          })
        )
      );
  }

  setExamResultAsViewed(id: number): Observable<any> {
    return this.http.put<any>(this.getMainUrl(`exams/${id}/viewed`), null);
  }

  getIdn(): Observable<any> {
    const url = this.getMainUrl('idn');
    const params: any = {
      [HTTP_PARAMS_SKIP_ERROR_HANDLING]: true,
      [HTTP_PARAMS_SKIP_LOADER_SHOWING]: true,
    };

    return this.http.get<any>(url, {params});
  }

  addIdn(idn: string): Observable<any> {
    const url = this.getMainUrl('idn');
    const body = {idn};

    return this.http.put<any>(url, body);
  }

  editIdn(idn: string): Observable<any> {
    const url = this.getMainUrl('idn');
    const body = {idn};

    return this.http.patch<any>(url, body);
  }

  sync(taskId: string): Observable<any> {
    return this.http.get<any>(this.getMainUrl(`sync/${taskId}`));
  }

  requestConsultee(payload: any): Observable<any> {
    const data = new FormData();
    data.append('id', '16');
    data.append('sec', 'qpbwmz');
    data.append('values', JSON.stringify(payload));

    return this.http.post<any>('https://joo.bitrix24.kz/bitrix/services/main/ajax.php?action=crm.site.form.fill', data);
  }
}
