import {ExamModel} from '@core/models/exam.model';

export class GetIdn {
  static readonly type = '[Unt] GetIdn';
}

export class RefreshNextChangeAfter {
  static readonly type = '[Unt] RefreshNextChangeAfter';
}

export class SetIdn {
  static readonly type = '[Unt] SetIdn';

  constructor(public idn: string) {
  }
}

export class ListExams {
  static readonly type = '[Unt] ListExams';

  constructor(public params?: any) {
  }
}

export class ViewExam {
  static readonly type = '[Unt] ViewExam';

  constructor(public exam: ExamModel) {
  }
}

export class ViewEnrollInfo {
  static readonly type = '[Unt] ViewEnrollInfo';
}

export class ClearExam {
  static readonly type = '[Unt] ClearExam';
}

export class RequestConsultee {
  static readonly type = '[Unt] RequestConsultee';
}
