<div class="wrapper" *ngIf="idn$ | async as idn">

  <div class="idn-wrapper">

    <div>
      <div class="label">{{'your_idn' | translate | async}}</div>
      <div class="value">{{idn}}</div>
    </div>

    <div class="edit-btn" routerLink="/idn-edit">{{'edit' | translate | async}}</div>

  </div>

  <ng-container *ngIf="exams$ | async as exams">

    <div class="unt" *ngFor="let exam of exams" (click)="onViewExam(exam)">

      <div class="flex-fill">

        <div class="name">{{exam | name | async}}</div>

        <ng-container [ngSwitch]="exam.status">

          <ng-container *ngSwitchCase="'not_registered'">
            <div class="status status-not-registered">
              {{'empty_results' | translate | async}}
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'has_result'">
            <div class="status status-has-result">
              {{'has_result' | translate | async}}
            </div>
          </ng-container>

        </ng-container>

      </div>

      <core-img name="arrow-right"></core-img>

    </div>

    <ng-container [ngSwitch]="refreshStatus">

      <ng-container *ngSwitchCase="'in_progress'">
        <div class="refreshing-hint">{{'refreshing_list' | translate | async}}</div>
      </ng-container>

      <ng-container *ngSwitchCase="'done'">
        <div class="refreshing-hint refreshing-hint-done">{{'refreshing_list_done' | translate | async}}</div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="refresh-exams-btn" (click)="onRefresh()">
          <core-img name="refresh" size="14"></core-img>
          <span>{{'press_to_refresh_exams' | translate | async}}</span>
        </div>
      </ng-container>

    </ng-container>

  </ng-container>

</div>
