<div class="wrapper" [formGroup]="formGroup">

  <div class="input-wrapper">

    <div class="label">{{'your_idn' | translate | async}}</div>

    <input
      #input
      type="tel"
      mask="000000000000"
      formControlName="idn"
      [placeholder]="'example_idn' | translate | async"
    />

  </div>

  <button
    class="btn btn-orange btn-block"
    [disabled]="formGroup.invalid"
    (click)="onSubmit()">{{'save' | translate | async}}</button>

</div>
