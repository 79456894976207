import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiService} from '@core/services/api.service';
import {DictionariesModel} from '@core/models/dictionaries.model';
import {HTTP_PARAMS_SKIP_LOADER_SHOWING} from '@core/constants/constants';


@Injectable({
  providedIn: 'root'
})
export class DictionariesService extends ApiService {

  constructor(
    protected http: HttpClient
  ) {
    super('dictionaries');
  }

  fetch(): Observable<DictionariesModel> {
    const url = this.getMainUrl();
    const params: any = {
      [HTTP_PARAMS_SKIP_LOADER_SHOWING]: true,
    };

    return this.http.get<DictionariesModel>(url, {params});
  }
}
