import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

import {UntState} from '@core/states/unt/unt.state';
import {ExamModel} from '@core/models/exam.model';


@Component({
  templateUrl: './unt-result.component.html',
  styleUrls: ['./unt-result.component.scss']
})
export class UntResultComponent {

  @Select(UntState.exam) exam$: Observable<ExamModel>;
}
