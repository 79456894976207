export const MOBILE_LANG = 'MOBILE_LANG';
export const MOBILE_ACCESS_TOKEN = 'MOBILE_ACCESS_TOKEN';
export const MOBILE_REFRESH_TOKEN = 'MOBILE_REFRESH_TOKEN';
export const MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE = 'MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE';
export const MOBILE_ONE_SIGNAL_PARAMS = 'MOBILE_ONE_SIGNAL_PARAMS';
export const MOBILE_ONE_SIGNAL_PARAMS_OBSERVABLE = 'MOBILE_ONE_SIGNAL_PARAMS_OBSERVABLE';
export const EVENT_HOUSE_SESSION_ID = 'EVENT_HOUSE_SESSION_ID';

export const HTTP_PARAMS_SKIP_LOADER_SHOWING = 'HTTP_PARAMS_SKIP_LOADER_SHOWING';
export const HTTP_PARAMS_SKIP_ERROR_HANDLING = 'HTTP_PARAMS_SKIP_ERROR_HANDLING';

export const UNT_PROFILE_SUBJECTS_ORDERS = [4, 5];
