<div class="wrapper" *ngIf="exam$ | async as exam">

  <div class="flex-fill">

    <div class="title">{{'congrats' | translate | async}}</div>

    <div class="subtitle">{{'you_get_score' | translate | async}}:</div>

    <div class="grade">
      <img src="./assets/img/wreath-left.svg">
      <div class="value">{{exam.result.total_score}}</div>
      <img src="./assets/img/wreath-right.svg">
    </div>

    <div class="subjects">

      <div class="subject" *ngFor="let subject of exam.result.subjects">
        <div class="name">{{subject | name : 'nct_name' | async}}</div>
        <div class="score">{{subject.total_score}}</div>
      </div>

    </div>

  </div>

  <div class="btn btn-white" routerLink="/group-list">
    {{'where_can_enroll' | translate | async}}
  </div>

</div>
