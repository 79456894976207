import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import * as moment from 'moment';

import {InitAppLang} from './actions';

import {LangType} from '@core/types/lang.type';
import {MOBILE_LANG} from '@core/constants/constants';
import {DictionariesModel} from '@core/models/dictionaries.model';
import {DictionariesService} from '@core/services/dictionaries.service';

interface AppStateModel {
  lang: LangType;
  dictionaries: DictionariesModel;
}

const defaults: AppStateModel = {
  lang: null,
  dictionaries: null,
};

@State<AppStateModel>({
  name: 'app',
  defaults
})
@Injectable()
export class AppState {

  @Selector()
  static lang({lang}: AppStateModel): LangType {
    return lang;
  }

  @Selector()
  static dictionaries({dictionaries}: AppStateModel): DictionariesModel {
    return dictionaries;
  }

  constructor(
    private store: Store,
    private dictionariesService: DictionariesService,
  ) {
  }

  @Action(InitAppLang)
  Init({patchState}: StateContext<AppStateModel>): Promise<any> {
    // rnWebviewLog(`SessionId: ${window[EVENT_HOUSE_SESSION_ID]}`);
    // rnWebviewLog(`SessionId: ${JSON.stringify({session: window[EVENT_HOUSE_SESSION_ID]})}`);

    const lang = window[MOBILE_LANG] as LangType;
    patchState({lang});
    moment.locale(lang);

    return this.dictionariesService.fetch()
      .toPromise()
      .then(dictionaries => {
        patchState({dictionaries});
      });
  }
}
