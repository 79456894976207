<div class="wrapper" *ngIf="exam$ | async as exam">

  <div class="content" #content>

    <div class="grade">
      <img src="./assets/img/wreath-left.svg">
      <div class="value">{{exam.result.total_score}}</div>
      <img src="./assets/img/wreath-right.svg">
    </div>

    <div class="subject" *ngFor="let subject of exam.result.subjects">
      <div class="name">{{subject | name : 'nct_name' | async}}</div>
      <div class="score">{{subject.total_score}}</div>
    </div>

<!--    <div class="banner" *ngIf="status">-->

<!--      <core-img name="edu-enroll-info-01" format="png" size="50"></core-img>-->

<!--      <div class="hint" [ngSwitch]="status">-->

<!--        <ng-container *ngSwitchCase="'no_groups'">-->
<!--          {{'enroll_info_not_enough_groups_hint' | translate | async}}-->
<!--        </ng-container>-->

<!--        <ng-container *ngSwitchCase="'not_enough_score'">-->
<!--          {{'enroll_info_not_enough_score_hint' | translate | async}}-->
<!--        </ng-container>-->

<!--      </div>-->

<!--    </div>-->

<!--    <div class="edu-info-01">-->

<!--      <div class="header" [innerHTML]="'enroll_info_title' | translate | async"></div>-->

<!--      <div class="feature">-->
<!--        <core-img name="edu-enroll-info-02" format="png" size="60"></core-img>-->
<!--        <div class="title" [innerHTML]="'enroll_info_feature_1_title' | translate | async"></div>-->
<!--        <div class="subtitle">{{'enroll_info_feature_1_subtitle' | translate | async}}</div>-->
<!--      </div>-->

<!--      <div class="feature">-->
<!--        <core-img name="edu-enroll-info-03" format="png" size="60"></core-img>-->
<!--        <div class="title" [innerHTML]="'enroll_info_feature_2_title' | translate | async"></div>-->
<!--        <div class="subtitle">{{'enroll_info_feature_2_subtitle' | translate | async}}</div>-->
<!--      </div>-->

<!--      <div class="feature">-->
<!--        <core-img name="edu-enroll-info-04" format="png" size="60"></core-img>-->
<!--        <div class="title" [innerHTML]="'enroll_info_feature_3_title' | translate | async"></div>-->
<!--        <div class="subtitle">{{'enroll_info_feature_3_subtitle' | translate | async}}</div>-->
<!--      </div>-->

<!--    </div>-->

<!--    <div class="edu-info-02">-->

<!--      <div class="title">-->
<!--        {{'edu_course_video' | translate | async}}-->
<!--      </div>-->

<!--      <div class="video">-->
<!--        <iframe-->
<!--          [src]="504288961 | vimeoUrl"-->
<!--          webkitallowfullscreen-->
<!--          mozallowfullscreen-->
<!--          allowfullscreen-->
<!--          frameBorder="0"-->
<!--        ></iframe>-->
<!--      </div>-->

<!--    </div>-->

  </div>

<!--  <div class="footer">-->
<!--    <div class="btn btn-orange" (click)="onRequestConsultee()">-->
<!--      {{'enroll_info_request_consultee' | translate | async}}-->
<!--    </div>-->
<!--  </div>-->

</div>
