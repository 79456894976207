import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';

import {RefreshNextChangeAfter, SetIdn} from '@core/states/unt/actions';
import {UntState} from '@core/states/unt/unt.state';
import {rnWebviewPostMessage} from '@core/utils/rn-webview';
import {TranslateService} from '@core/services/translate.service';


@Component({
  templateUrl: './idn-edit.component.html',
  styleUrls: ['./idn-edit.component.scss']
})
export class IdnEditComponent implements AfterViewInit {

  @ViewChild('input') input: ElementRef;

  formGroup: FormGroup;

  state = null;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private translateService: TranslateService,
  ) {
    this.formGroup = this.formBuilder.group({
      idn: [null, Validators.required],
    });

    const idn = this.store.selectSnapshot(UntState.idn);
    if (idn) {
      this.formGroup.patchValue({idn});
    }

    this.store.dispatch(RefreshNextChangeAfter);
  }

  ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.showError();
      return;
    }

    const idn = this.formGroup.get('idn').value;
    this.store.dispatch(new SetIdn(idn));
  }

  showError(): void {
    rnWebviewPostMessage({
      event: 'alert',
      data: {
        title: this.translateService.instant('errors_title'),
        message: this.translateService.instant('errors_idn')
      }
    });
  }
}
