import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import localeKk from '@assets/i18n/kk';
import localeRu from '@assets/i18n/ru';
import {AppState} from '@core/states/app/app.state';
import {rnWebviewLog} from '@core/utils/rn-webview';


@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private store: Store
  ) {
  }

  transform(query: string): Observable<string> {
    return this.store.select(AppState.lang)
      .pipe(
        map(lang => {
          switch (lang) {
            case 'kk':
              return localeKk.data[query] ? localeKk.data[query].toString() : query;
            case 'ru':
              return localeRu.data[query] ? localeRu.data[query].toString() : query;
          }

          return query;
        })
      );
  }
}
