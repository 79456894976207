<div class="wrapper" *ngIf="groups$ | async as groups">

  <div class="title">{{'group_list_title' | translate | async}}</div>

  <div class="subtitle">{{'group_list_subtitle' | translate | async}}</div>

  <div class="group" *ngFor="let group of groups" (click)="onNavigateGroup(group.id)">

    <div class="flex-fill">
      <div class="name">{{group.name}}</div>
      <div class="code">Код: {{group.code}}</div>
    </div>

    <core-img name="arrow-right"></core-img>

  </div>

<!--  <div class="edu-info">-->

<!--    <div class="header" [innerHTML]="'group_list_enroll_info_title' | translate | async"></div>-->

<!--    <div class="btn btn-orange mb-5" (click)="onRequestConsultee()">-->
<!--      {{'enroll_info_request_consultee' | translate | async}}-->
<!--    </div>-->

<!--    <div class="feature">-->
<!--      <core-img name="edu-enroll-info-02" format="png" size="60"></core-img>-->
<!--      <div class="title" [innerHTML]="'enroll_info_feature_1_title' | translate | async"></div>-->
<!--      <div class="subtitle">{{'enroll_info_feature_1_subtitle' | translate | async}}</div>-->
<!--    </div>-->

<!--    <div class="feature">-->
<!--      <core-img name="edu-enroll-info-03" format="png" size="60"></core-img>-->
<!--      <div class="title" [innerHTML]="'enroll_info_feature_2_title' | translate | async"></div>-->
<!--      <div class="subtitle">{{'enroll_info_feature_2_subtitle' | translate | async}}</div>-->
<!--    </div>-->

<!--    <div class="feature">-->
<!--      <core-img name="edu-enroll-info-04" format="png" size="60"></core-img>-->
<!--      <div class="title" [innerHTML]="'enroll_info_feature_3_title' | translate | async"></div>-->
<!--      <div class="subtitle">{{'enroll_info_feature_3_subtitle' | translate | async}}</div>-->
<!--    </div>-->

<!--    <div class="header mt-4" [innerHTML]="'group_list_enroll_info_subtitle' | translate | async"></div>-->

<!--    <div class="btn btn-orange" (click)="onRequestConsultee()">-->
<!--      {{'enroll_info_request_consultee' | translate | async}}-->
<!--    </div>-->

<!--  </div>-->

</div>
