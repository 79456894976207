import {environment} from '@env';


export class ApiService {

  constructor(
    protected uri: string
  ) {
  }

  private getFinalUrl(mainUrl: string, extraBranch?: string | number): string {
    if (extraBranch) {
      mainUrl += `/${extraBranch}`;
    }

    return `${mainUrl}/`;
  }

  protected getMainUrl(extraBranch?: string | number): string {
    return this.getFinalUrl(`${environment.apiMainUrl}/${this.uri}`, extraBranch);
  }

  protected getCoursesUrl(extraBranch?: string | number): string {
    return this.getFinalUrl(`${environment.apiCoursesUrl}/${this.uri}`, extraBranch);
  }
}
