import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';

import {UntState} from '@core/states/unt/unt.state';
import {rnWebviewPostMessage} from '@core/utils/rn-webview';
import {RequestConsultee} from '@core/states/unt/actions';


@Component({
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent {

  @Select(UntState.groups) groups$: Observable<any[]>;

  constructor(
    private store: Store
  ) {
  }

  onNavigateGroup(id: number): void {
    rnWebviewPostMessage({
      event: 'on_navigate_mobile',
      data: {
        method: 'navigate',
        args: ['EduGroupViewScreen', {id}]
      }
    });
  }

  onRequestConsultee(): void {
    this.store.dispatch(RequestConsultee);
  }
}
