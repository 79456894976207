import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import * as moment from 'moment';
import {Moment} from 'moment';

import {InitAuthToken, Logout, UpdateToken} from './actions';

import {GetIdn} from '@core/states/unt/actions';
import {TokenModel} from '@core/models/token.model';
import {InitAppLang} from '@core/states/app/actions';
import {OneSignalParamsService} from '@core/services/one-signal-params.service';
import {MOBILE_ACCESS_TOKEN, MOBILE_ONE_SIGNAL_PARAMS, MOBILE_REFRESH_TOKEN} from '@core/constants/constants';

interface AuthStateModel {
  token: TokenModel;
  accessTokenExpireDate?: Moment;
}

const defaults = {
  token: null,
  accessTokenExpireDate: null,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults
})
@Injectable()
export class AuthState {

  @Selector()
  static token({token}: AuthStateModel): TokenModel {
    return token;
  }

  @Selector()
  static accessTokenExpireDate({accessTokenExpireDate}: AuthStateModel): Moment {
    return accessTokenExpireDate;
  }

  constructor(
    private store: Store,
    private oneSignalParamsService: OneSignalParamsService,
  ) {
  }

  @Action(InitAuthToken)
  InitAuthToken(): void {
    const token = {
      access: window[MOBILE_ACCESS_TOKEN],
      refresh: window[MOBILE_REFRESH_TOKEN]
    };
    this.store.dispatch([new UpdateToken(token), InitAppLang, GetIdn]);
    this.oneSignalParamsService.handleOneSignalParams(window[MOBILE_ONE_SIGNAL_PARAMS]);
  }

  @Action(UpdateToken)
  UpdateToken({patchState}: StateContext<AuthStateModel>, {token}: UpdateToken): void {
    const decodedAccessToken = JSON.parse(window.atob(token.access.split('.')[1]));

    patchState({
      token,
      accessTokenExpireDate: moment.unix(decodedAccessToken.exp),
    });
  }

  @Action(Logout)
  Logout({}: StateContext<AuthStateModel>): void {
    // TODO: force mobile app to log out
  }
}
