import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';

import {ExamModel} from '@core/models/exam.model';
import {UntState} from '@core/states/unt/unt.state';
import {rnWebviewPostMessage} from '@core/utils/rn-webview';
import {RequestConsultee} from '@core/states/unt/actions';


@Component({
  templateUrl: './enroll-info.component.html',
  styleUrls: ['./enroll-info.component.scss']
})
export class EnrollInfoComponent {

  @Select(UntState.exam) exam$: Observable<ExamModel>;

  @ViewChild('content') content: ElementRef;

  status: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
  ) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.status = queryParams.status;
    });
  }

  onNavigateEduWebView(): void {
    rnWebviewPostMessage({
      event: 'on_navigate_mobile',
      data: {
        method: 'navigate',
        args: ['EduWebViewScreen']
      }
    });
  }

  onRequestConsultee(): void {
    this.content.nativeElement.scrollTo({
      top: this.content.nativeElement.scrollHeight,
      behavior: 'smooth'
    });
    this.store.dispatch(RequestConsultee);
  }
}
