import {LocaleModel} from '@core/models/locale.model';


const localeKk: LocaleModel = {
  lang: 'kk',
  data: {
    errors_title: 'Қате',
    errors_not_found: 'Табылмады',
    errors_server_error: 'Сервер қатесі орын алды. Көмек алу үшін жүйелік әкімшіге хабарласыңыз.',
    errors_idn: '12 цифрдан тұратын дұрыс ЖСН енгізіңіз',
    your_idn: 'Сіздің ЖСН',
    edit: 'Өзгерту',
    loading: 'Күте тұрыңыз...',
    example_idn: 'Мысалы, 123456789012',
    save: 'Сақтау',
    starts: 'Басталуы',
    waiting_exam_start: 'ҰБТ басталуын күтудеміз',
    idn: 'ЖСН',
    ikt: 'ИКТ',
    exam_date: 'Тестілеу<br>күні',
    exam_time: 'Тестілеу<br>уақыты',
    exam_place: 'Тестілеу жері',
    exam_address: 'Адрес',
    exam_building: 'Ғимарат',
    exam_room: 'Кабинет',
    not_registered: 'Сіз тіркелген жоқсыз',
    waiting_result: 'Нәтижені күтудеміз',
    has_result: 'Нәтиже дайын',
    refreshing_list: 'Тізімді жаңартудамыз...',
    refreshing_list_done: 'Тізім жаңартылды!',
    press_to_refresh_exams: 'Тізімді жаңарту үшін басыңыз',
    congrats: 'Құттықтаймыз!',
    you_get_score: 'Сіз жинаған ұпайыңыз',
    where_can_enroll: 'Қайда оқуға түсе алам?',
    warning: 'Назар аударыңыз',
    exam_not_registered: 'Сіз бұл емтиханға әлі тіркелген жоқсыз',
    empty_results: 'Біз нәтижелерді алмадық',
    enroll_info_not_enough_score_hint: 'Өкінішке орай, сіз қажетті шектік баллды жинай алмадыңыз, яғни сіз бұл нәтижемен ақылы бөлімге де тапсыра алмайсыз.',
    enroll_info_not_enough_groups_hint: 'Өкінішке орай, осы жинаған баллыңызбен грантқа түсе алмайсыз. Бірақ, сізде ақылы бөлімге түсуге мүмкіндік бар.',
    enroll_info_title: 'Бірақ Joo.kz<br> сізді ҰБТ-ға дайындап, <span class="highlighted">грантқа түсетініңізге кепілдік бере алады!</span>',
    enroll_info_feature_1_title: '60 000 ₸<br>5 пән үшін, 1 жылға',
    enroll_info_feature_1_subtitle: 'Біз грантқа кепілдік береміз. Грант ұтпаған жағдайда ақшаңызды қайтарып береміз. ҰБТ дайындығына бірегей курс ұсынамыз.',
    enroll_info_feature_2_title: 'Грантқа түсетініңізге кепілдік<br>береміз',
    enroll_info_feature_2_subtitle: 'Оқушы грантқа түспей қалған жағдайда бізге оқуғу төлеген ақшасын толығымен қайтарып береміз.',
    enroll_info_feature_3_title: 'Тегін консультация<br>береміз',
    enroll_info_feature_3_subtitle: 'ҰБТ тапсырып болғаннан кейін біз оқушының қайда грантқа түсе алатынын, немесе қайда түсе алмайтынын айтып береміз.',
    enroll_info_request_consultee: 'Курсқа жазылу',
    enroll_info_request_consultee_success: 'Рақмет, сіздің өтінішіңіз жіберілді. Сізге тез арада хабарласамыз.',
    enroll_info_request_consultee_error: 'Өтінішті жіберу кезінде қате пайда болды. Көмек үшін біздің әлеуметтік желілерімізге хабарласыңыз. Біз міндетті түрде жауап береміз.',
    group_list_title: 'Осы жинаған баллмен қайда грантқа түсе алатын едіңіз?',
    group_list_subtitle: 'Бұл негізгі ҰБТ болмағандықтан, бұл ҰБТ нәтижесімен тек ақылы бөлімге тапсыра аласыз.',
    group_list_enroll_info_title: '<span class="highlighted">Joo.kz</span>-тен<br>ҰБТ-ға дайындық<br>курсына жазылыңыз',
    group_list_enroll_info_subtitle: 'Бізбен бірге дайындалыңыз!',
    edu_course_video: 'Біздің курс туралы видео',
    no_idn_edit_attempts: 'Сізде ЖСН-ді өзгертуге қалған мүмкіндік саны таусылды',
    next_change_after: 'ЖСН-ді өзгертуге болатын уақыт',
    idn_edit_attempt_confirm: 'ЖСН-ді өзгертуге қалған мүмкіндік саны: <b>{{attempts}}</b>.<br><br>Сіз ЖСН ауыстырғыңыз келе ме?',
    idn_edit_attempt_confirm_text: 'Иә, ЖСН-ді өзгерткім келеді',
    idn_edit_attempt_cancel_text: 'Жоқ, өзгерткім келмейді',
    plug_title: 'Бұл бөлімде сіз ҰБТ нәтижеңізді көре аласыз',
    plug_subtitle: 'ҰБТ нәтижеңізді пуш-хабарлама арқылы жібереміз',
  }
};

export default localeKk;
