import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiService} from '@core/services/api.service';


@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ApiService {

  constructor(
    protected http: HttpClient
  ) {
    super('profile');
  }

  fetch(): Observable<any> {
    return this.http.get<any>(this.getMainUrl());
  }
}
