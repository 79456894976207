<div class="modal-body">

  <div class="wrapper">

    <div class="content">
      <img src="./assets/img/dog-in-water.svg">
      <div class="message" [innerHTML]="options.message | translate | async"></div>
    </div>

    <div class="footer">

      <div class="footer-btn" [class.btn-highlighted]="options.cancelText" (click)="onConfirm()">
        {{options.confirmText | translate | async}}
      </div>

      <div class="footer-btn" *ngIf="options.cancelText" (click)="onClose()">
        {{options.cancelText | translate | async}}
      </div>

    </div>

  </div>

</div>
