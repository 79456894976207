import {LocaleModel} from '@core/models/locale.model';


const localeRu: LocaleModel = {
  lang: 'ru',
  data: {
    errors_title: 'Ошибка',
    errors_not_found: 'Не найдено',
    errors_server_error: 'Возникла ошибка сервера. Пожалуйста, обратитесь за помощью к системному администратору.',
    errors_idn: 'Введите правильный ИИН, состоящий из 12 цифр',
    your_idn: 'Ваш ИИН',
    edit: 'Изменить',
    loading: 'Подождите...',
    example_idn: 'Например, 123456789012',
    save: 'Сохранить',
    starts: 'Начало',
    waiting_exam_start: 'Ожидаем начала ЕНТ',
    idn: 'ИИН',
    ikt: 'ИКТ',
    exam_date: 'Дата<br>тестирования',
    exam_time: 'Время<br>тестирования',
    exam_place: 'Место тестирования',
    exam_address: 'Адрес',
    exam_building: 'Здание',
    exam_room: 'Кабинет',
    not_registered: 'Вы не зарегистрированы',
    waiting_result: 'Ожидаем результаты',
    has_result: 'Результаты готовы',
    refreshing_list: 'Обновляем список...',
    refreshing_list_done: 'Список успешно обновлен!',
    press_to_refresh_exams: 'Нажмите, чтобы обновить данные',
    congrats: 'Поздравляем!',
    you_get_score: 'Вы получили баллов',
    where_can_enroll: 'Куда можно поступить?',
    warning: 'Внимание',
    exam_not_registered: 'Вы еще не зарегистрированы на этот экзамен',
    empty_results: 'Мы не получили результат',
    enroll_info_not_enough_score_hint: 'К сожалению, вы не набрали проходной балл, то есть вы не сможете с этим результатом подать даже на платное обучение.',
    enroll_info_not_enough_groups_hint: 'К сожалению, с вашим текущим баллом вы не смогли бы поступить на грант. Но у вас есть возможность поступить на платное отделение.',
    enroll_info_title: 'Но Joo.kz<br> подготовит вас к ЕНТ и гарантирует, <span class="highlighted">что вы поступите на грант!</span>',
    enroll_info_feature_1_title: '60 000 ₸<br>на 5 предметов в год',
    enroll_info_feature_1_subtitle: 'Мы гарантируем грант. Если не поступите, то вернем деньги. Предлагаем уникальный курс подготовки к ЕНТ.',
    enroll_info_feature_2_title: 'Гарантируем грант<br>на обучение',
    enroll_info_feature_2_subtitle: 'Если ученик не поступит на грант,то мы вернем деньги, что он заплатил нам.',
    enroll_info_feature_3_title: 'Бесплатная<br>консультация',
    enroll_info_feature_3_subtitle: 'После сдачи ЕНТ мы покажем ученику на какие специальности он может поступить, а на какие поступить не может.',
    enroll_info_request_consultee: 'Записаться на курс',
    enroll_info_request_consultee_success: 'Спасибо, ваше сообщение отправлено. Мы свяжемся с вами как можно быстрее.',
    enroll_info_request_consultee_error: 'Возникла ошибка при отправке вашей заявки. Обратитесь за помощью в наши социальные сети. Мы обязательно поможем вам.',
    group_list_title: 'Куда бы вы могли поступить на грант с вашим баллом?',
    group_list_subtitle: 'Так как это не основной ЕНТ, с этим баллом вы можете подавать только  на платное обучение.',
    group_list_enroll_info_title: 'Запишитесь на курс подготовки к ЕНТ<br>на <span class="highlighted">Joo.kz</span>',
    group_list_enroll_info_subtitle: 'Готовьтесь<br>вместе с нами!',
    edu_course_video: 'Видео про наш курс',
    no_idn_edit_attempts: 'У вас не осталось попыток изменить ИИН',
    next_change_after: 'Изменение доступно через',
    idn_edit_attempt_confirm: 'Оставшееся количество попыток изменения ИИН: <b>{{attempts}}</b>.<br><br>Хотите изменить ИИН?',
    idn_edit_attempt_confirm_text: 'Да, хочу поменять ИИН',
    idn_edit_attempt_cancel_text: 'Нет, не хочу менять',
    plug_title: 'В этом разделе вы увидите свои результаты по ЕНТ',
    plug_subtitle: 'Заполните свой ИИН и мы отправим ваши результаты пуш-уведомлением',
  }
};

export default localeRu;
