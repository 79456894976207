import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';

import localeKk from '@assets/i18n/kk';
import localeRu from '@assets/i18n/ru';
import {AppState} from '@core/states/app/app.state';


@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(
    private store: Store
  ) {
  }

  instant(query: string): string {
    const lang = this.store.selectSnapshot(AppState.lang);

    switch (lang) {
      case 'kk':
        return localeKk.data[query].toString();
      case 'ru':
        return localeRu.data[query].toString();
    }

    return query;
  }
}
