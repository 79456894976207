import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, switchMap, take, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import * as moment from 'moment';

import {AuthState} from '@core/states/auth/auth.state';
import {Logout, UpdateToken} from '@core/states/auth/actions';
import {HTTP_PARAMS_SKIP_ERROR_HANDLING} from '@core/constants/constants';
import {rnWebviewPostMessage} from '@core/utils/rn-webview';
import {AppState} from '@core/states/app/app.state';
import {TranslateService} from '@core/services/translate.service';
import {TokenModel} from '@core/models/token.model';
import {AuthService} from '@core/services/auth.service';
import {environment} from '@env';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  isRefreshingTokenInProgress: boolean;

  onFinishRefreshingToken: Subject<any>;
  onFinishRefreshingToken$: Observable<any>;

  onMobileAppFinishRefreshingToken$: Observable<TokenModel>;

  constructor(
    private store: Store,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {
    this.isRefreshingTokenInProgress = false;

    this.onFinishRefreshingToken = new Subject();
    this.onFinishRefreshingToken$ = this.onFinishRefreshingToken.asObservable();

    this.authService.onMobileAppFinishRefreshingToken$
      .subscribe(newToken => {
        this.store.dispatch(new UpdateToken(newToken));
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // rnWebviewPostMessage({
    //   event: 'log',
    //   data: `Request url: ${request.url}`
    // });
    rnWebviewPostMessage({
      event: 'log',
      data: JSON.stringify(request),
    });

    request = this.applyRequestHeaders(request);

    if (this.isTokenExpired()) {
      return this.launchRefreshToken(request, next);
    }

    return next.handle(request)
      .pipe(
        catchError(error => {
          return this.handleResponseError(error, request, next);
        })
      );
  }

  applyRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
    request = request.clone({
      setHeaders: {
        'Accept-Language': this.store.selectSnapshot(AppState.lang),
      }
    });

    if (request.url.startsWith(environment.apiMainUrl)) {
      const token = this.store.selectSnapshot(AuthState.token);
      if (token) {
        return request.clone({
          setHeaders: {Authorization: `Bearer ${token.access}`}
        });
      }
    }

    return request;
  }

  isTokenExpired(): boolean {
    const accessTokenExpireDate = this.store.selectSnapshot(AuthState.accessTokenExpireDate);
    const leftSeconds = moment().diff(accessTokenExpireDate, 'seconds');
    rnWebviewPostMessage({
      event: 'log',
      data: `Access token expiration left seconds: ${leftSeconds}}`
    });
    return leftSeconds > -35;
  }

  handleResponseError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    rnWebviewPostMessage({
      event: 'log',
      data: `Request error: ${request.url} | ${JSON.stringify(request)} | ${JSON.stringify(error)}`
    });

    if (request.params.has(HTTP_PARAMS_SKIP_ERROR_HANDLING)) {
      return throwError(error);
    }

    if (error.status === 401) {
      return this.launchRefreshToken(request, next);
    }

    let message: string;
    if (error.status >= 500) {
      message = this.translateService.instant('errors_server_error');
    } else if (error.status === 404) {
      message = this.translateService.instant('errors_not_found');
    } else {
      message = this.extractHttpErrorMessage(error.error);
    }
    rnWebviewPostMessage({
      event: 'alert',
      data: {
        title: this.translateService.instant('errors_title'),
        message
      }
    });

    return throwError(error);
  }

  launchRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.refreshToken(request)
      .pipe(
        switchMap(() => {
          request = this.applyRequestHeaders(request);
          return next.handle(request);
        }),
        catchError(error => {
          this.store.dispatch(Logout);
          return throwError(error);
        })
      );
  }

  refreshToken(request: HttpRequest<any>): Observable<any> {
    if (this.isRefreshingTokenInProgress) {
      rnWebviewPostMessage({
        event: 'log',
        data: `Waits for refresh: ${request.url}`
      });

      return this.onFinishRefreshingToken$
        .pipe(
          take(1),
          tap(() => {
            rnWebviewPostMessage({
              event: 'log',
              data: `Finished waiting - re-launch request: ${request.url}`
            });
          })
        );
    } else {
      this.isRefreshingTokenInProgress = true;

      rnWebviewPostMessage({
        event: 'on_refresh_token',
        data: request.url
      });
      rnWebviewPostMessage({
        event: 'log',
        data: `Try to refresh token: ${request.url}`
      });

      return this.authService.onMobileAppFinishRefreshingToken$
        .pipe(
          take(1),
          tap(() => {
            this.isRefreshingTokenInProgress = false;
            this.onFinishRefreshingToken.next();

            rnWebviewPostMessage({
              event: 'log',
              data: `Successfully refreshed - update tokens: ${request.url}`
            });
          })
        );
    }
  }

  extractHttpErrorMessage(data: any): string {
    if (!data || typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      return this.extractHttpErrorMessage(data[0]);
    } else {
      const objKeys = Object.keys(data);
      return this.extractHttpErrorMessage(data[objKeys[0]]);
    }
  }
}
