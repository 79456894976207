import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppState} from '@core/states/app/app.state';
import {NameModel} from '@core/models/name.model';


@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {

  constructor(
    private store: Store,
  ) {
  }

  transform(entity: any, attr = 'name'): Observable<string> {
    return this.store.select(AppState.lang)
      .pipe(
        map(lang => {
          return lang ? entity[`${attr}_${lang}`] : null;
        })
      );
  }
}
