import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

import {PopLoaderQueue, PushLoaderQueue} from '@core/states/loader/actions';
import {HTTP_PARAMS_SKIP_LOADER_SHOWING} from '@core/constants/constants';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private store: Store
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.params.has(HTTP_PARAMS_SKIP_LOADER_SHOWING)) {
      return next.handle(
        request.clone({
          params: request.params.delete(HTTP_PARAMS_SKIP_LOADER_SHOWING)
        })
      );
    }

    this.store.dispatch(new PushLoaderQueue(request.url));

    return next.handle(request)
      .pipe(
        finalize(() => {
          this.store.dispatch(new PopLoaderQueue(request.url));
        })
      );
  }
}
