import {Component} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {filter} from 'rxjs/operators';
import 'moment/locale/ru';
import 'moment/locale/kk';

// import {runLocalEnvironmentSettings} from '../environments/environment.local';

import {InitAuthToken} from '@core/states/auth/actions';
import {rnWebviewPostMessage} from '@core/utils/rn-webview';
import {OneSignalParamsService} from '@core/services/one-signal-params.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
    private store: Store,
    private oneSignalParamsService: OneSignalParamsService,
  ) {
    // runLocalEnvironmentSettings();
    this.initOneSignalParamsSubscription();
    this.initAuthToken();
    this.handleNavigationChange();
  }

  initOneSignalParamsSubscription(): void {
    this.oneSignalParamsService.oneSignalParams$
      .subscribe(params => {
        this.oneSignalParamsService.handleOneSignalParams(params);
      });
  }

  initAuthToken(): void {
    setTimeout(() => this.store.dispatch(InitAuthToken), 500);
  }

  handleNavigationChange(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(({url}: NavigationStart) => {
        let payload: any;

        switch (true) {
          case url === '/':
            payload = {
              title: {
                kk: 'ҰБТ тізімі',
                ru: 'Список ЕНТ'
              },
              scrollEnabled: true,
              canGoBack: false,
              orientation: 'lockToPortrait'
            };
            break;
          case url.includes('unt-view'):
            payload = {
              title: {
                kk: 'ҰБТ нәтижесі',
                ru: 'Результаты ЕНТ'
              },
              scrollEnabled: true,
              canGoBack: true,
              orientation: 'lockToPortrait'
            };
            break;
          case url.includes('idn-edit'):
            payload = {
              title: {
                kk: 'ЖСН енгізіңіз',
                ru: 'Введите ИИН'
              },
              scrollEnabled: false,
              canGoBack: !url.includes('noIdn'),
              orientation: 'lockToPortrait'
            };
            break;
          case url.includes('unt-result'):
          case url.includes('enroll-info'):
            payload = {
              title: {
                kk: 'ҰБТ нәтижесі',
                ru: 'Результат ЕНТ'
              },
              scrollEnabled: true,
              canGoBack: true,
              orientation: 'lockToPortrait'
            };
            break;
          case url.includes('group-list'):
            payload = {
              title: {
                kk: 'Мамандықтар',
                ru: 'Специальности'
              },
              scrollEnabled: true,
              canGoBack: true,
              orientation: 'lockToPortrait'
            };
            break;
        }

        rnWebviewPostMessage({
          event: 'on_navigate',
          data: payload
        });
      });
  }
}
