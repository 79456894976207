import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UntListComponent} from '@features/unt-list/unt-list.component';
import {IdnEditComponent} from '@features/idn-edit/idn-edit.component';
import {UntResultComponent} from '@features/unt-result/unt-result.component';
import {GroupListComponent} from '@features/group-list/group-list.component';
import {EnrollInfoComponent} from '@features/enroll-info/enroll-info.component';


const routes: Routes = [
  {
    path: '',
    component: UntListComponent,
    pathMatch: 'full',
  },
  {
    path: 'idn-edit',
    component: IdnEditComponent,
  },
  {
    path: 'unt-result',
    component: UntResultComponent,
  },
  {
    path: 'group-list',
    component: GroupListComponent,
  },
  {
    path: 'enroll-info',
    component: EnrollInfoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
