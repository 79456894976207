import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {EMPTY, Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Navigate} from '@ngxs/router-plugin';
import * as moment from 'moment';

import {ClearExam, GetIdn, ListExams, RefreshNextChangeAfter, RequestConsultee, SetIdn, ViewExam} from './actions';

import {UntService} from '@core/services/unt.service';
import {rnWebviewLog, rnWebviewPostMessage} from '@core/utils/rn-webview';
import {ExamModel} from '@core/models/exam.model';
import {TranslateService} from '@core/services/translate.service';
import {PopLoaderQueue, PushLoaderQueue} from '@core/states/loader/actions';
import {GroupsService} from '@core/services/groups.service';
import {ProfileService} from '@core/services/profile.service';
import {getProfileSubjects} from '@core/utils/utils';
import {DialogService} from '@core/services/dialog.service';
import {MOBILE_LANG} from '@core/constants/constants';
import {Duration} from 'moment';


interface UntStateModel {
  idn: string;
  editIdnAttempts: number;
  nextChangeAfter: number;
  exams: ExamModel[];
  exam: ExamModel;
  groups: any[];
}

const defaults: UntStateModel = {
  idn: null,
  editIdnAttempts: null,
  nextChangeAfter: null,
  exams: [],
  exam: null,
  groups: [],
};

@State<UntStateModel>({
  name: 'unt',
  defaults
})
@Injectable()
export class UntState {

  @Selector()
  static idn({idn}: UntStateModel): string {
    return idn;
  }

  @Selector()
  static exams({exams}: UntStateModel): ExamModel[] {
    return exams;
  }

  @Selector()
  static exam({exam}: UntStateModel): ExamModel {
    return exam;
  }

  @Selector()
  static groups({groups}: UntStateModel): any[] {
    return groups;
  }

  constructor(
    private untService: UntService,
    private store: Store,
    private location: Location,
    private translateService: TranslateService,
    private groupsService: GroupsService,
    private profileService: ProfileService,
    private dialogService: DialogService,
  ) {
  }

  @Action(GetIdn)
  GetIdn({patchState}: StateContext<UntStateModel>): Observable<any> {
    return this.untService.getIdn()
      .pipe(
        switchMap(({idn, changes_remains, next_change_after}) => {
          if (idn) {
            rnWebviewLog(`changes_remains: ${changes_remains}`);
            patchState({idn, editIdnAttempts: changes_remains, nextChangeAfter: next_change_after});

            return this.store.dispatch(ListExams)
              .toPromise()
              .finally(() => {
                rnWebviewPostMessage({event: 'on_load'});
              });
          }

          return this.store.dispatch(new Navigate(['/idn-edit'], {noIdn: true}))
            .toPromise()
            .finally(() => {
              rnWebviewPostMessage({event: 'on_load'});
            });
        })
      );
  }

  @Action(RefreshNextChangeAfter)
  RefreshNextChangeAfter({patchState}: StateContext<UntStateModel>): void {
    this.untService.getIdn()
      .toPromise()
      .then(({next_change_after}) => {
        patchState({nextChangeAfter: next_change_after});
      });
  }

  @Action(SetIdn)
  async SetIdn({getState, patchState}: StateContext<UntStateModel>, {idn}: SetIdn): Promise<any> {
    const hasIdn = getState().idn;
    const {editIdnAttempts, nextChangeAfter} = getState();

    if (hasIdn) {
      if (!editIdnAttempts) {
        const noAttemptText = this.translateService.instant('no_idn_edit_attempts');
        const nextChangeAfterText = this.translateService.instant('next_change_after');

        const duration: Duration = moment.duration(nextChangeAfter * 1000);
        let days = duration.days().toString();
        let hours = duration.hours().toString();
        let minutes = duration.minutes().toString();

        switch (window[MOBILE_LANG]) {
          case 'kk':
            days += ' күн';
            hours += ' сағат';
            minutes += ' минут';
            break;
          case 'ru':
            days += ' д.';
            hours += ' ч.';
            minutes += ' м';
            break;
        }

        this.dialogService.show({
          message: `${noAttemptText}.<br><br>${nextChangeAfterText}:<br>${days} ${hours} ${minutes}.`,
          onConfirm: () => this.location.back()
        });
        return;
      }

      await new Promise(resolve => {
        this.dialogService.show({
          message: this.translateService
            .instant('idn_edit_attempt_confirm')
            .replace('{{attempts}}', editIdnAttempts.toString()),
          confirmText: 'idn_edit_attempt_confirm_text',
          cancelText: 'idn_edit_attempt_cancel_text',
          onConfirm: resolve,
        });
      });
    }

    const setIdnRequest = hasIdn
      ? this.untService.editIdn(idn)
      : this.untService.addIdn(idn);

    return setIdnRequest
      .toPromise()
      .then(({task_id}) => {
        this.store.dispatch(new PushLoaderQueue(task_id));

        this.untService.getIdn()
          .toPromise()
          .then(({changes_remains}) => {
            rnWebviewLog(`changes_remains: ${changes_remains}`);
            patchState({idn, editIdnAttempts: changes_remains});
          });

        const interval = setInterval(() => {
          this.untService.sync(task_id)
            .toPromise()
            .then(({status}) => {
              switch (true) {
                case status === 'SUCCESS':
                  this.location.back();
                  this.store.dispatch([ListExams, new PopLoaderQueue(task_id)]);
                  clearInterval(interval);
                  break;
                case ['FAILURE', 'REVOKED'].includes(status):
                  this.location.back();
                  this.store.dispatch([ListExams, new PopLoaderQueue(task_id)]);
                  clearInterval(interval);

                  rnWebviewPostMessage({
                    event: 'alert',
                    data: {
                      title: 'Внимание',
                      message: 'Мы не смогли получить ваши данные. Мы обязательно обновим их как только будет возможно.'
                    }
                  });
                  break;
              }
            })
            .catch(() => {
              patchState({exam: null});
              this.location.back();
              this.store.dispatch([ListExams, new PopLoaderQueue(task_id)]);
              clearInterval(interval);
            });
        }, 5000);
      });
  }

  @Action(ListExams)
  ListUnt({patchState}: StateContext<UntStateModel>, {params}: ListExams): Promise<any> {
    return this.untService.listExams(params)
      .toPromise()
      .then(exams => {
        patchState({exams});
      });
  }

  @Action(ViewExam)
  ViewExam({getState, patchState}: StateContext<UntStateModel>, {exam}: ViewExam): Observable<any> | void {
    patchState({exam});

    switch (exam.status) {
      case 'not_registered':
        rnWebviewPostMessage({
          event: 'alert',
          data: {
            title: this.translateService.instant('warning'),
            message: this.translateService.instant('empty_results')
          }
        });
        return EMPTY;
      case 'has_result':
        return this.untService.setExamResultAsViewed(exam.id)
          .pipe(
            switchMap(() => {
              const notEnoughTotalScore = exam.result.total_score < 50;
              const notEnoughSubjectScore = exam.result.subjects.filter(subject => subject.total_score < 5).length > 0;

              if (notEnoughTotalScore || notEnoughSubjectScore) {
                return this.store.dispatch(new Navigate(['/enroll-info'], {status: 'not_enough_score'}));
              }

              const subjects = getProfileSubjects(exam.result.subjects);
              const profileSubjectIds = subjects.filter(({subject_id}) => subject_id > 0);

              if (profileSubjectIds.length !== 2) {
                return this.store.dispatch(new Navigate(['/enroll-info']));
              }

              const params = {
                subjects: profileSubjectIds.map(({subject_id}) => subject_id).join(','),
                min_stat_grade_lte: exam.result.total_score,
              };

              return this.groupsService.fetch(params)
                .pipe(
                  switchMap(({results, count}) => {
                    if (count === 0) {
                      return this.store.dispatch(new Navigate(['/enroll-info'], {status: 'no_groups'}));
                    }

                    patchState({groups: results});
                    return this.store.dispatch(new Navigate(['/unt-result']));
                  }),
                );
            })
          );
    }
  }

  @Action(ClearExam)
  ClearExam({patchState}: StateContext<UntStateModel>): void {
    patchState({exam: null});
  }

  @Action(RequestConsultee)
  RequestConsultee({getState}: StateContext<UntStateModel>): Observable<any> {
    const {idn, exam} = getState();

    const subjects = getProfileSubjects(exam.result.subjects).map(({nct_name_ru}) => nct_name_ru).join(', ');

    return this.profileService.fetch()
      .pipe(
        switchMap(({phone, full_name}) => {
          let contactName;
          switch (true) {
            case exam.result.full_name !== null:
              contactName = exam.result.full_name;
              break;
            default:
              contactName = full_name;
          }

          const payload = {
            CONTACT_NAME: [`${contactName}`],
            CONTACT_PHONE: [phone],
            DEAL_UF_CRM_1590584441289: [idn],
            DEAL_UF_CRM_1590672555816: [subjects],
            DEAL_UF_CRM_1611050143268: [exam.result.total_score],
          };

          return this.untService.requestConsultee(payload)
            .toPromise()
            .then(({error}) => {
              if (error) {
                rnWebviewPostMessage({
                  event: 'alert',
                  data: {
                    title: this.translateService.instant('enroll_info_request_consultee'),
                    message: this.translateService.instant('enroll_info_request_consultee_error')
                  }
                });
              } else {
                rnWebviewPostMessage({
                  event: 'alert',
                  data: {
                    title: this.translateService.instant('enroll_info_request_consultee'),
                    message: this.translateService.instant('enroll_info_request_consultee_success')
                  }
                });
              }
            });
        })
      );

  }
}
